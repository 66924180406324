import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const StatusMaintenance = Loader(
  lazy(() => import("src/pages/Maintenance"))
);

const routes: RouteObject[] = [
  {
    path: "*",
    element: <StatusMaintenance />,
  },
];

export default routes;
